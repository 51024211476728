import { Injectable } from '@angular/core';
import { BaseApiService } from '../../../core/services/base-api.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiResourceEnum } from '../../../core/enums/api-resource-enum';
import { InsertShipmentDto } from '../dto/insert-shipment.dto';
import { ResponseShipmentDto } from '../dto/response-shipment.dto';
import { ResponseShipmentFindDto } from '../dto/response-shipment-find.dto';
import { ScanRfidDto } from '../dto/scan-rfid.dto';
import { DeviceRfidDto } from '../dto/device-rfid.dto';

@Injectable({
  providedIn: 'root',
})
export class ShipmentService extends BaseApiService {

  constructor(private http: HttpClient) {
    super();
  }

  createShipment(shipment: InsertShipmentDto): Observable<ResponseShipmentDto> {
    return this.http.post<ResponseShipmentDto>(
      `${this.baseUrl}/${ApiResourceEnum.SHIPMENTS}`,
      shipment,
      {
        headers: this.httpHeaders,
      },
    );
  }

  getShipments(): Observable<ResponseShipmentFindDto[]> {
    return this.http.get<ResponseShipmentFindDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.SHIPMENTS}`,
      {
        headers: this.httpHeaders,
      },
    );
  }


  scanRfid(deviceid: string): Observable<ScanRfidDto> {
    return this.http.get<ScanRfidDto>(
      `${this.baseUrl}/${ApiResourceEnum.SCANTAG}/${deviceid}`,
      {
        headers: this.httpHeaders,
      },
    );
  }

  lockRfid(deviceid: string,lockValue: string): Observable<ScanRfidDto> {
    return this.http.post<ScanRfidDto>(
      `${this.baseUrl}/${ApiResourceEnum.LOCKRFID}`,
      {
        "deviceid" : deviceid,
        "lockValue": lockValue
      },
      {
        headers: this.httpHeaders,
      },
    );
  }

  unlockRfid(deviceid: string): Observable<ScanRfidDto> {
    return this.http.post<ScanRfidDto>(
      `${this.baseUrl}/${ApiResourceEnum.UNLOCKRFID}`,
      {
        "deviceid" : deviceid,
      },
      {
        headers: this.httpHeaders,
      },
    );
  }

  getRfidUser(): Observable<DeviceRfidDto[]> {
    return this.http.get<DeviceRfidDto[]>(
      `${this.baseUrl}/${ApiResourceEnum.GETUSERRFID}`,
      {
        headers: this.httpHeaders,
      },
    );
  }
}
