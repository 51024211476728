import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { BatchService } from '../../services/batch.service';
import { ResponseBatchDto } from '../../dto/response-batch.dto';
import { UpsertBatchDto } from '../../dto/upsert-batch.dto';
import { Button } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { LabelDescriptionCustomComponent } from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { NgClass, NgForOf, NgIf, NgStyle } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TabMenuModule } from 'primeng/tabmenu';
import { CalendarModule } from 'primeng/calendar';
import { UploadButtonCustomComponent } from '../../../../shared/components/upload-button-custom/upload-button-custom.component';
import { ExtensionsEnum } from '../../../../core/enums/extensions-enum';
import { environment } from '../../../../../environments/environment';
import { SeedingTypeEnum } from '../../enum/seeding-type.enum';
import { GrowingProcessTypeEnum } from '../../enum/growing-process-type.enum';
import { ResponseProductDto } from '../../../product/dto/response-product.dto';
import { ResponseProductionSiteDto } from '../../../production-sites/dto/response-production-site.dto';
import { ProductService } from '../../../product/services/product.service';
import { ProductionSiteService } from '../../../production-sites/services/production-site.service';
import { StorageService } from '../../../../core/services/storage.service';
import { TabViewModule } from 'primeng/tabview';
import { TabPanelEnum } from '../../enum/tab-panel-enum';
import { ResponseAttachmentDto } from '../../../../core/models/response-attachment.dto';

@Component({
  selector: 'app-upsert-batch',
  standalone: true,
  imports: [
    Button,
    DialogModule,
    DropdownModule,
    FloatLabelModule,
    InputNumberModule,
    InputTextModule,
    LabelDescriptionCustomComponent,
    NgIf,
    PrimeTemplate,
    ReactiveFormsModule,
    TranslateModule,
    TabMenuModule,
    CalendarModule,
    UploadButtonCustomComponent,
    NgStyle,
    NgForOf,
    NgClass,
    FormsModule,
    TabViewModule,
  ],
  templateUrl: './upsert-batch.component.html',
  styleUrl: './upsert-batch.component.scss',
})
export class UpsertBatchComponent implements OnInit {
  originRoute!: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private batchService: BatchService,
    private productService: ProductService,
    private productionSiteService: ProductionSiteService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private storageService: StorageService,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.batchId = params.get('id') ?? '';
    });
    const navigation = this.router.getCurrentNavigation();
    if (navigation?.extras?.state) {
      this.originRoute = navigation.extras.state['originRoute'];
    }
  }

  batchFormFirst!: FormGroup;
  batchFormFourth!: FormGroup;
  batchId!: string;
  iotDeviceNumber!: string | null;
  batch!: ResponseBatchDto;
  dialogGoBackVisible: boolean = false;
  dialogUpsertVisible: boolean = false;
  codeLabel: string = '';
  tabs: { title: string; content: string }[] = [];
  activeIndex!: number;
  typeSeeding!: { label: string; value: string }[];
  typeGrowingProcess!: { label: string; value: string }[];
  products!: ResponseProductDto[];
  productionSites!: ResponseProductionSiteDto[];
  selectedOGMFilesAttachment: File[] = [];
  selectedOGMFilesAttachmentFromGet: ResponseAttachmentDto[] = [];
  selectedAntibioticFilesAttachmentFromGet: ResponseAttachmentDto[] = [];
  selectedNutrientsAttachmentFromGet: ResponseAttachmentDto[] = [];
  selectedAntibioticFilesAttachment: File[] = [];
  selectedNutrientsAttachment: File[] = [];
  numberFileLimit: number = 1;
  unlockedTabs: number[] = [TabPanelEnum.PROPERTIES];
  initialUnlockedTabs: number[] = [];
  hasOGMAttachments: boolean = false;
  hasAntibioticAttachments: boolean = false;
  hasNutrientsAttachments: boolean = false;
  visibleUploadDialog!: boolean;
  attachmentDeleted: boolean = false;
  nutrientiOriginalSize = 0;

  ngOnInit(): void {
    this.getProducts();
    this.getProductionSites();
    this.getIoTDevice();
    this.initForms();
    this.tabs = [
      {
        title: 'features.batch-page.tab-menu-header.properties',
        content: '',
      },
      {
        title: 'features.batch-page.tab-menu-header.certifications',
        content: '',
      },
      {
        title: 'features.batch-page.tab-menu-header.device',
        content: '',
      },
      {
        title: 'features.batch-page.tab-menu-header.nutrients',
        content: '',
      },
    ];
    this.typeSeeding = Object.values(SeedingTypeEnum).map((value) => ({
      label: this.translateService.instant(`seedingTypeEnum.label.${value}`),
      value: value,
    }));
    this.typeGrowingProcess = Object.values(GrowingProcessTypeEnum).map(
      (value) => ({
        label: this.translateService.instant(
          `growingProcessTypeEnum.label.${value}`,
        ),
        value: value,
      }),
    );
    this.initialUnlockedTabs = [TabPanelEnum.PROPERTIES];

    this.activeIndex = TabPanelEnum.PROPERTIES;
    if (this.batchId) {
      this.unlockedTabs = this.tabs.map((_, index) => index);
      this.getBatch();
    } else {
      this.unlockedTabs = [...this.initialUnlockedTabs];
    }
    this.batchFormFirst.valueChanges.subscribe(() => this.handleFormChange());
  }

  handleFormChange(): void {
    if (this.batchFormFirst.invalid) {
      if (!this.batchId) {
        this.unlockedTabs = [...this.initialUnlockedTabs];
      }
    }
  }

  getProducts(): void {
    this.productService.getProducts().subscribe({
      next: (response: ResponseProductDto[]) => {
        this.products = response;
      },
      error: (err) => {},
    });
  }

  getProductionSites(): void {
    this.productionSiteService.getProductionSites().subscribe({
      next: (response: ResponseProductionSiteDto[]) => {
        this.productionSites = response;
      },
      error: (err) => {},
    });
  }

  getIoTDevice(): void {
    const producerId = this.storageService.getProducer();
    this.iotDeviceNumber = producerId?.deviceId as string | null;
  }

  get nutrientsControls() {
    return (this.batchFormFourth.get('nutrients') as FormArray).controls;
  }

  addNutrientField(): void {
    const nutrientsArray = this.batchFormFourth.get('nutrients') as FormArray;
    nutrientsArray.push(this.createNutrientField());
  }

  removeNutrientField(index: number): void {
    const nutrientsArray = this.batchFormFourth.get('nutrients') as FormArray;
    if (nutrientsArray.length > 1) {
      nutrientsArray.removeAt(index);
    }
  }

  createNutrientField(): FormGroup {
    return new FormGroup({
      nutrient: new FormControl(null, Validators.required),
      value: new FormControl(null, Validators.required),
    });
  }

  initForms(): void {
    this.batchFormFirst = new FormGroup({
      code: new FormControl(null, Validators.required),
      productId: new FormControl(null, Validators.required),
      productionSiteId: new FormControl(null, Validators.required),
      seedingDate: new FormControl(null, Validators.required),
      seedingType: new FormControl(null, Validators.required),
      growingProcessType: new FormControl(null, Validators.required),
      quantity: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        this.integerValidator,
      ]),
    });

    this.batchFormFourth = new FormGroup({
      nutrients: new FormArray([this.createNutrientField()]),
    });
  }

  integerValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!Number.isInteger(value)) {
      return { notInteger: true };
    }
    return null;
  }

  getTabDisabled(index: number): boolean {
    if (this.batchFormFirst.invalid) {
      return (
        index !== TabPanelEnum.PROPERTIES &&
        !this.initialUnlockedTabs.includes(index)
      );
    }
    return !this.unlockedTabs.includes(index);
  }

  getBatch(): void {
    if (this.batchId) {
      this.batchService.getBatchById(this.batchId).subscribe({
        next: (response: ResponseBatchDto) => {
          this.batch = response;
          const seedingDate = new Date(response.seedingDate);
          this.codeLabel = response.code;
          if (response.OMGFreeCertAttachments) {
            if (response.OMGFreeCertAttachments?.length > 0) {
              this.hasOGMAttachments = true;
              this.selectedOGMFilesAttachmentFromGet =
                response.OMGFreeCertAttachments;
            }
          }
          if (response.antibioticFreeCertAttachments) {
            if (response.antibioticFreeCertAttachments?.length > 0) {
              this.hasAntibioticAttachments = true;
              this.selectedAntibioticFilesAttachmentFromGet =
                response.antibioticFreeCertAttachments;
            }
          }
          if (response.nutrientsFreeCertAttachments) {
            if (response.nutrientsFreeCertAttachments?.length > 0) {
              this.hasNutrientsAttachments = true;
              this.selectedNutrientsAttachmentFromGet =
                response.nutrientsFreeCertAttachments;
            }
          }
          for (let i = 0; i < response.nutrients.length - 1; i++) {
            this.addNutrientField();
          }
          this.nutrientiOriginalSize = response.nutrients.length;
          this.batchFormFirst.patchValue({
            productionSiteId: response.productionSiteId,
            productId: response.productId,
            code: response.code,
            seedingDate: seedingDate,
            seedingType: response.seedingType,
            growingProcessType: response.growingProcessType,
            quantity: response.quantity,
          });
          this.batchFormFourth.patchValue({
            nutrients: response.nutrients.map((nutrient) => ({
              nutrient: nutrient.nutrient,
              value: nutrient.value,
            })),
          });
        },
        error: (err) => {
          console.error('Error fetching batch data:', err);
        },
      });
    }
  }

  onOGMFilesSelected(files: File[]): void {
    this.selectedOGMFilesAttachment = files;
  }

  onAntibioticFilesSelected(files: File[]): void {
    this.selectedAntibioticFilesAttachment = files;
  }

  onNutrientsFilesSelected(files: File[]): void {
    this.selectedNutrientsAttachment = files;
  }

  onFileRemoved(event: { fileKey: string }) {
    if (this.batchId) {
      this.batchService
        .deleteAttachment(this.batchId, event.fileKey)
        .subscribe({
          next: () => {
            if (this.batch.OMGFreeCertAttachments) {
              this.batch.OMGFreeCertAttachments =
                this.batch.OMGFreeCertAttachments.filter(
                  (i) => i.fileKey !== event.fileKey,
                );
            }
            if (this.batch.antibioticFreeCertAttachments) {
              this.batch.antibioticFreeCertAttachments =
                this.batch.antibioticFreeCertAttachments.filter(
                  (i) => i.fileKey !== event.fileKey,
                );
            }
            if (this.batch.nutrientsFreeCertAttachments) {
              this.batch.nutrientsFreeCertAttachments =
                this.batch.nutrientsFreeCertAttachments.filter(
                  (i) => i.fileKey !== event.fileKey,
                );
            }
            this.attachmentDeleted = true;
          },

          error: (err) => {
            this.visibleUploadDialog = false;
          },
        });
    }
  }

  getButtonLabel(): string {
    if (this.activeIndex === this.tabs.length - 1) {
      return this.batchId ? 'shared.update' : 'shared.create';
    } else {
      return 'shared.continue';
    }
  }

  handleButtonClick(): void {
    if (this.activeIndex < this.tabs.length - 1) {
      if (this.isCurrentTabValid()) {
        this.unlockNextTab();
        this.goToNextTab();
      }
    } else {
      this.openDialogConfirm();
    }
  }

  isCurrentTabValid(): boolean {
    return !this.batchFormFirst.invalid;
  }

  unlockNextTab(): void {
    const nextIndex = this.activeIndex + 1;
    if (
      nextIndex < this.tabs.length &&
      !this.unlockedTabs.includes(nextIndex)
    ) {
      this.unlockedTabs.push(nextIndex);
    }
  }

  goToNextTab(): void {
    this.activeIndex++;
  }

  isButtonDisabled(): boolean {
    const hasFiles =
      this.selectedOGMFilesAttachment.length > 0 ||
      this.selectedAntibioticFilesAttachment.length > 0 ||
      this.selectedNutrientsAttachment.length > 0;
    if (this.batchId) {
      if (this.activeIndex === TabPanelEnum.IOT_DEVICE) {
        return false;
      }
      switch (this.activeIndex) {
        case TabPanelEnum.PROPERTIES:
          return this.batchFormFirst.invalid;
        case TabPanelEnum.CERTIFICATIONS:
          return false;
        case TabPanelEnum.NUTRIENTS:
          return (
            this.batchFormFourth.invalid ||
            (this.nutrientiOriginalSize == (this.batchFormFourth.get('nutrients') as FormArray).length && this.batchFormFirst.pristine &&
              this.batchFormFourth.pristine &&
              !hasFiles &&
              !this.attachmentDeleted)
          );
        default:
          return true;
      }
    } else {
      if (
        this.activeIndex === TabPanelEnum.CERTIFICATIONS ||
        this.activeIndex === TabPanelEnum.IOT_DEVICE
      ) {
        return false;
      }
      switch (this.activeIndex) {
        case TabPanelEnum.PROPERTIES:
          return this.batchFormFirst.invalid || this.batchFormFirst.pristine;
        case TabPanelEnum.NUTRIENTS:
          return this.batchFormFourth.invalid || this.batchFormFourth.pristine;
        default:
          return true;
      }
    }
  }

  onUpsertBatch(): void {
    if (this.batchFormFirst.valid && this.batchFormFourth.valid) {
      const payload = this.createPayload();
      if (this.batchId) {
        this.updateBatch(payload);
      } else {
        this.createBatch(payload);
      }
    } else {
    }
  }

  private createPayload(): UpsertBatchDto {
    const payload: UpsertBatchDto = {
      ...this.batchFormFirst.getRawValue(),
      ...this.batchFormFourth.getRawValue(),
    };

    if (this.selectedOGMFilesAttachment.length > 0) {
      payload.OMGFreeCertFiles = this.selectedOGMFilesAttachment;
    }

    if (this.selectedAntibioticFilesAttachment.length > 0) {
      payload.antibioticFreeCertFiles = this.selectedAntibioticFilesAttachment;
    }

    if (this.selectedNutrientsAttachment.length > 0) {
      payload.nutrientsFreeCertFiles = this.selectedNutrientsAttachment;
    }

    return payload;
  }

  private updateBatch(payload: UpsertBatchDto): void {
    this.batchService.updateBatch(payload, this.batchId).subscribe({
      next: (response: ResponseBatchDto) => this.onSuccess(),
      error: (err) => {
        this.closeDialogConfirm();
      },
    });
  }

  private createBatch(payload: UpsertBatchDto): void {
    this.batchService.createBatch(payload).subscribe({
      next: (response: ResponseBatchDto) => this.onSuccess(),
      error: (err) => {
        this.closeDialogConfirm();
      },
    });
  }

  private onSuccess(): void {
    let summary = this.translateService.instant('shared.success');
    let detailCreate = this.translateService.instant(
      'shared.create-product-success',
    );
    let detailUpdate = this.translateService.instant(
      'shared.update-product-success',
    );
    if (this.batchId) {
      this.messageService.add({
        severity: 'success',
        summary: summary,
        detail: detailUpdate,
        styleClass: 'custom-toast-success',
      });
    } else {
      this.messageService.add({
        severity: 'success',
        summary: summary,
        detail: detailCreate,
        styleClass: 'custom-toast-success',
      });
    }

    setTimeout(() => {
      this.goBack();
    }, 1000);
  }

  closeDialogConfirm(): void {
    this.dialogUpsertVisible = false;
  }

  closeDialogBack(): void {
    this.dialogGoBackVisible = false;
  }

  openDialogConfirm(): void {
    this.dialogUpsertVisible = true;
  }

  openDialogBack(): void {
    if (!this.batchId) {
      if (!this.batchFormFirst.touched) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    } else {
      if (this.batchFormFirst.pristine) {
        this.goBack();
      } else {
        this.dialogGoBackVisible = true;
      }
    }
  }

  handleButtonBackClick(): void {
    if (this.activeIndex > TabPanelEnum.PROPERTIES) {
      this.activeIndex--;
    } else {
      this.openDialogBack();
    }
  }

  goBack(): void {
    if (this.originRoute) {
      void this.router.navigate([this.originRoute]);
    } else {
      void this.router.navigate([RoutingResourceEnum.HOME]);
    }
  }

  protected readonly ExtensionsEnum = ExtensionsEnum;
  protected readonly environment = environment;
  protected readonly TabPanelEnum = TabPanelEnum;
}
