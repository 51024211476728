import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { catchError, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { StorageService } from '../services/storage.service';
import { RoutingResourceEnum } from '../enums/routing-resource-enum';
import { TranslateService } from '@ngx-translate/core';
import { ErrorCodeEnum } from '../enums/error-code-enum';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: StorageService,
    private router: Router,
    private messageService: MessageService,
    private translateService: TranslateService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessageKey: string = '';
        if ('errorCode' in error.error) {
          switch (error.error.errorCode) {
            case ErrorCodeEnum.TOKEN_EXPIRED:
              errorMessageKey = 'core.pages.error.E001';
              this.authService.removeAll();
              void this.router.navigate([RoutingResourceEnum.LOGIN]);
              break;
            case ErrorCodeEnum.DUPLICATE_KEY:
              errorMessageKey = 'core.pages.error.E002';
              break;
            case ErrorCodeEnum.DUPLICATE_NUTRIENT:
              errorMessageKey = 'core.pages.error.E003';
              break;
            case ErrorCodeEnum.QR_CODES_NOT_AVAILABLE:
              errorMessageKey = 'core.pages.error.E010';
              break;
            case ErrorCodeEnum.RFID_NOT_AVAILABLE:
              errorMessageKey = 'core.pages.error.E011';
              break;
          }
        } else {
          switch (error.status) {
            case HttpStatusCode.Unauthorized:
              // errorMessageKey = 'core.pages.error.401';
              // TODO temporary
              this.authService.removeAll();
              void this.router.navigate([RoutingResourceEnum.LOGIN]);
              break;
            case HttpStatusCode.Forbidden:
              errorMessageKey = 'core.pages.error.403';
              break;
            case HttpStatusCode.BadRequest:
            case HttpStatusCode.UnprocessableEntity:
              errorMessageKey = 'core.pages.error.400-422';
              break;
            case HttpStatusCode.Conflict:
              errorMessageKey = 'core.pages.error.409';
              break;
            default:
              errorMessageKey = 'core.pages.error.others';
              break;
          }
        }
        let errorMessage = this.composeErrorMessage(error);
        let translatedMessage;
        if (errorMessageKey) {
          translatedMessage = this.translateService.instant(
            errorMessageKey,
            errorMessage ? { message: errorMessage } : {},
          );
        }
        this.messageService.add({
          severity: 'error',
          summary: this.translateService.instant('shared.error'),
          detail: translatedMessage,
          styleClass: 'custom-toast-error',
        });

        throw error;
      }),
    );
  }

  composeErrorMessage(error: HttpErrorResponse): string {
    let errorMessage = '';
    if (error.error.message) {
      if (typeof error.error.message === 'string') {
        errorMessage = error.error.message;
      } else if (Array.isArray(error.error.message)) {
        errorMessage = error.error.message.join(', ');
      } else {
        errorMessage = error.message;
      }
    } else {
      errorMessage = error.message;
    }
    return errorMessage;
  }
}
