import { Component, OnInit } from '@angular/core';
import { Button } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import {
  LabelDescriptionCustomComponent,
} from '../../../../shared/components/label-description-custom/label-description-custom.component';
import { NgClass } from '@angular/common';
import { MessageService, PrimeTemplate } from 'primeng/api';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CustomerService } from '../../../customer/services/customer.service';
import { ResponseCustomerDto } from '../../../customer/dto/response-customer.dto';
import { RoutingResourceEnum } from '../../../../core/enums/routing-resource-enum';
import { InsertShipmentDto } from '../../dto/insert-shipment.dto';
import { ShipmentService } from '../../services/shipment.service';
import { ResponseShipmentDto } from '../../dto/response-shipment.dto';
import { BatchService } from '../../../batch/services/batch.service';
import { ResponseBatchDto } from '../../../batch/dto/response-batch.dto';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { InputGroupModule } from 'primeng/inputgroup';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { TooltipModule } from 'primeng/tooltip';
import { QrCodesService } from '../../../qr-code/service/qr-codes.service';
import { RangeDto } from '../../dto/range.dto';
import { ToastModule } from 'primeng/toast';
import { KnobModule } from 'primeng/knob';
import { StorageService } from '../../../../core/services/storage.service';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'app-shipment-create',
  standalone: true,
  imports: [
    Button,
    DialogModule,
    DropdownModule,
    FloatLabelModule,
    FormsModule,
    InputNumberModule,
    InputTextModule,
    LabelDescriptionCustomComponent,
    PrimeTemplate,
    ReactiveFormsModule,
    TranslateModule,
    NgClass,
    InputGroupAddonModule,
    InputGroupModule,
    IconFieldModule,
    InputIconModule,
    TooltipModule,
    ToastModule,
    KnobModule,
    CalendarModule,
  ],
  templateUrl: './create-shipment.component.html',
  styleUrl: './create-shipment.component.scss',
})
export class CreateShipmentComponent implements OnInit {


  public countdownInt = 30;
  public countdown = 0;
  public showScanModal = false;
  public rfidDeviceId: string | null = null;


  constructor(
    private router: Router,
    private customerService: CustomerService,
    private shipmentService: ShipmentService,
    private batchService: BatchService,
    private qrCodesService: QrCodesService,
    private translateService: TranslateService,
    private messageService: MessageService,
    private storageService: StorageService,
  ) {
  }

  shipmentForm!: FormGroup;
  customers!: ResponseCustomerDto[];
  batches!: ResponseBatchDto[];
  dialogGoBackVisible: boolean = false;
  dialogInsertVisible: boolean = false;
  availableQRCodes!: number;
  range: RangeDto = { min: 0, max: 0 };


  ngOnInit(): void {
    this.initForm();
    this.getCustomers();
    this.getBatches();
    this.loadAvailableSequenceRange();
    this.initRfidDevice();
  }

  initForm(): void {
    this.shipmentForm = new FormGroup({
      batchCode: new FormControl(null, Validators.required),
      customer: new FormControl(null, Validators.required),
      rfid: new FormControl({ value: null, disabled: true }),
      quantity: new FormControl(null, [
        Validators.required,
        Validators.min(1),
        this.integerValidator,
      ]),
      qrCodeQuantity: new FormControl({ value: null, disabled: true }, [
        Validators.required,
        Validators.min(1),
        this.integerValidator,
      ]),
      shipmentDate: new FormControl(new Date()),

    });
    this.shipmentForm.get('customer')?.valueChanges.subscribe((customerId) => {
      const selectedCustomer = this.customers.find(
        (customers) => customers._id === customerId,
      );
      if (selectedCustomer) {
        this.shipmentForm.get('qrCodeQuantity')?.enable();
        this.shipmentForm.patchValue({
          qrCodeQuantity: selectedCustomer.numberOfStores,
        });
      } else {
        this.shipmentForm.get('qrCodeQuantity')?.disable();
      }
    });
  }

  getCustomers() {
    this.customerService.getCustomers().subscribe({
      next: (customers: ResponseCustomerDto[]) => {
        this.customers = customers;
      },
      error: (err) => {
      },
    });
  }

  getBatches() {
    this.batchService.getBatches().subscribe({
      next: (batches: ResponseBatchDto[]) => {
        this.batches = batches;
      },
      error: (err) => {
      },
    });
  }

  onInsertShipment(): void {
    if (this.shipmentForm.valid) {
      let payload: InsertShipmentDto;
      payload = {
        batchId: this.shipmentForm.get('batchCode')?.value.toLowerCase(),
        customerId: this.shipmentForm.get('customer')?.value,
        productQuantity: this.shipmentForm.get('quantity')?.value,
        QRCodeQuantity: this.shipmentForm.get('qrCodeQuantity')?.value,
        rfid: this.shipmentForm.get('rfid')?.value,
        shipmentDate: this.shipmentForm.get('shipmentDate')?.value,
      };
      this.shipmentService.createShipment(payload).subscribe({
        next: (response: ResponseShipmentDto) => {
          this.onSuccess();
          this.goBack();
        },
        error: (err) => {
          this.closeDialogConfirm();
        },
      });
    }
  }

  closeDialogConfirm(): void {
    this.dialogInsertVisible = false;
  }

  closeDialogBack(): void {
    this.dialogGoBackVisible = false;
  }

  openDialogConfirm(): void {
    this.qrCodesService
      .getQrCodeRange(this.shipmentForm.get('qrCodeQuantity')?.value)
      .subscribe({
        next: (range: RangeDto) => {
          this.dialogInsertVisible = true;
          this.range.max = range.max;
          this.range.min = range.min;
        },
        error: (err) => {
        },
      });
  }

  loadAvailableSequenceRange(): void {
    this.qrCodesService.getQrCodeRange(0).subscribe((range: RangeDto) => {
      this.availableQRCodes =
        range.min && range.max ? range.max - range.min + 1 : 0;
    });
  }

  openDialogBack(): void {
    if (!this.shipmentForm.touched) {
      this.goBack();
    } else {
      this.dialogGoBackVisible = true;
    }
  }

  goBack(): void {
    void this.router.navigate([RoutingResourceEnum.HOME]);
  }

  integerValidator(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (!Number.isInteger(value)) {
      return { notInteger: true };
    }
    return null;
  }

  private onSuccess(): void {
    let summary = this.translateService.instant('shared.success');
    let detail = this.translateService.instant('shared.create-product-success');

    this.messageService.add({
      severity: 'success',
      summary: summary,
      detail: detail,
      styleClass: 'custom-toast-success',
    });
    setTimeout(() => {
      this.goBack();
    }, 1000);
  }

  public showRfidModal(): void {
    if (this.rfidDeviceId) {
      this.shipmentService.lockRfid(this.rfidDeviceId, (Math.floor(Math.random() * 10000)).toString()).subscribe(
        {
          next: data => {
            {
              this.showScanModal = true;
              this.countdown = this.countdownInt;
              setTimeout(() => {
                this.scanRfid();
              }, 1000);
            }

          },
          error: err => {
            console.log(`Error: ${err}`);
          },
        },
      );
    }

  }

  private scanRfid() {
    if (this.countdown && this.showScanModal) {
      if (this.rfidDeviceId) {
        this.shipmentService.scanRfid(this.rfidDeviceId).subscribe(
          {
            next: data => {
              if (data.epc) {
                this.shipmentForm.get('rfid')?.setValue(data.epc);
                this.countdown = 0;
                this.showScanModal = false;
              }

            },
          },
        );
      }
      this.countdown--;
      setTimeout(() => {
        this.scanRfid();
      }, 1000);
    } else {
      this.showScanModal = false;
    }


  }

  closeRfidScan() {
    this.showScanModal = false;
    this.countdown = 0;
    if (this.rfidDeviceId) {
      this.shipmentService.unlockRfid(this.rfidDeviceId).subscribe(
        {
          next: data => {
            {
              this.showScanModal = false;
              this.countdown = 0;
            }

          },
          error: err => {
            //console.log(`Error: ${err}`);
          },
        },
      );
    }
  }

  private initRfidDevice() {

    this.shipmentService.getRfidUser().subscribe(
      {
        next: data => {
          //console.log(JSON.stringify(data));
          if (data.length > 0) {
            this.rfidDeviceId = data[0].deviceid;
          }
          //console.log(this.epc);
        },
        error: err => {
          //console.log(err);
        },
      },
    );

  }

  clearRfidValue() {
    this.shipmentForm.get('rfid')?.setValue(null);

  }

}
