import { Component, OnInit } from '@angular/core';
import { CardButtonCustomComponent } from '../../../shared/components/card-button-custom/card-button-custom.component';
import { TranslateModule } from '@ngx-translate/core';
import { IotDataService } from '../../../features/iot-data/services/iot-data.service';
import { Button } from 'primeng/button';
import { GraphOptionFactoryService } from './graph-option-factory.service';
import { ResponseIotDataDto } from '../../../features/iot-data/dto/response-iot-data.dto';
import { ECBasicOption } from 'echarts/types/src/util/types';
import { IotDataMeasured } from '../../../features/iot-data/constants/iot-data-measured.constant';
import { GraphComponent } from '../graph/graph.component';
import { ShipmentService } from '../../../features/shipment/services/shipment.service';
import { DatePipe, NgIf } from '@angular/common';
import { ResponseShipmentFindDto } from '../../../features/shipment/dto/response-shipment-find.dto';
import { CircularArray } from '../../utils/circular-array';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResponseBatchDto } from '../../../features/batch/dto/response-batch.dto';
import { BatchService } from '../../../features/batch/services/batch.service';

@Component({
  selector: 'app-graphs-panel',
  standalone: true,
  imports: [
    CardButtonCustomComponent,
    TranslateModule,
    Button,
    GraphComponent,
    DatePipe,
    NgIf,
    DropdownModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './graphs-panel.component.html',
  styleUrl: './graphs-panel.component.scss',
})
export class GraphsPanelComponent implements OnInit {
  graphTypeArray = new CircularArray(IotDataMeasured);
  graphOption!: ECBasicOption;
  shipments!: CircularArray<ResponseShipmentFindDto>;
  iotData!: ResponseIotDataDto[];
  batches!: ResponseBatchDto[];
  selectedBatch!: string;
  uniqueBatchesMap = new Map<string, CircularArray<ResponseShipmentFindDto>>();

  constructor(
    private readonly iotDataService: IotDataService,
    private readonly shipmentService: ShipmentService,
    private readonly factory: GraphOptionFactoryService,
  ) {}

  ngOnInit(): void {
    this.getBatches();
  }

  selectBatch() {
    const batchKey = Array.from(this.uniqueBatchesMap.keys()).find(
      (batchJson) => {
        const batch = JSON.parse(batchJson);
        return batch._id === this.selectedBatch;
      },
    );
    if (batchKey) {
      const circularArray = this.uniqueBatchesMap.get(batchKey);
      if (circularArray) {
        this.shipments = circularArray;
      }
    }
  }

  getBatches() {
    this.shipmentService
      .getShipments()
      .subscribe((shipments: ResponseShipmentFindDto[]) => {
        shipments.forEach((shipment) => {
          const batch = shipment.batch;
          let circularArray = this.uniqueBatchesMap.get(JSON.stringify(batch));
          if (!circularArray) {
            circularArray = new CircularArray<ResponseShipmentFindDto>([]);
            this.uniqueBatchesMap.set(JSON.stringify(batch), circularArray);
          }
          circularArray.push(shipment);
        });
        this.batches = Array.from(this.uniqueBatchesMap.keys()).map((i) =>
          JSON.parse(i),
        );
        if (this.batches.length > 0) {
          this.selectedBatch = this.batches[0]._id;
          this.selectBatch();
        }
        this.loadIotData();
      });
  }

  loadIotData(): void {
    this.iotDataService
      .findByDate(this.selectedShipmentDate as Date)
      .subscribe((IoTdata) => {
        const field = this.graphTypeArray.current();
        this.iotData = IoTdata;
        this.graphOption = this.factory.create(field, IoTdata);
      });
  }

  switchMeasured(): void {
    const field = this.graphTypeArray.current();
    this.graphOption = this.factory.create(field, this.iotData);
  }

  /**
   * Shipment functions
   */

  prevShipment(): void {
    this.shipments.prev();
    this.loadIotData();
  }

  nextShipment(): void {
    this.shipments.next();
    this.loadIotData();
  }

  isPrevShipmentDisabled(): boolean {
    return this.isBlankShipment() || this.shipments.isCurrentFirst();
  }

  isNextShipmentDisabled(): boolean {
    return this.isBlankShipment() || this.shipments.isCurrentLast();
  }

  isBlankShipment(): boolean {
    return !this.shipments || this.shipments.isEmpty();
  }

  isFilledShipment(): boolean {
    return !this.isBlankShipment();
  }

  get selectedShipmentNumber(): number | null {
    if (this.shipments && !this.shipments.isEmpty()) {
      return this.shipments.getElementNumber();
    }
    return null;
  }

  get selectedShipmentDate(): Date | null {
    if (this.isFilledShipment()) {
      return new Date(
        (
          this.shipments.current() as ResponseShipmentFindDto
        ).createdAt.valueOf(),
      );
    }
    return null;
  }

  get selectedBatchCode(): string | undefined {
    if (this.isFilledShipment()) {
      return (this.shipments.current() as ResponseShipmentFindDto).batch.code;
    }
    return undefined;
  }

  /**
   * Graph functions
   */

  prevGraph(): void {
    this.graphTypeArray.prev();
    this.switchMeasured();
  }

  nextGraph(): void {
    this.graphTypeArray.next();
    this.switchMeasured();
  }

  get selectedArrayType(): string {
    return this.graphTypeArray.current();
  }

  /**
   * Data functions
   */

  isEmptyData(): boolean {
    return !this.iotData || !this.iotData.length;
  }

  isFilledData(): boolean {
    return !this.isEmptyData();
  }
}
