import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseBatchDto } from '../../batch/dto/response-batch.dto';
import { BatchService } from '../../batch/services/batch.service';
import { ResponseTraceBatchDto } from '../dto/response-trace-batch.dto';
import { PanelModule } from 'primeng/panel';
import {
  LabelDescriptionCustomComponent,
} from '../../../shared/components/label-description-custom/label-description-custom.component';
import { DatePipe, formatDate, NgClass, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ResponseAttachmentDto } from '../../../core/models/response-attachment.dto';
import { Button, ButtonDirective } from 'primeng/button';
import { AccordionModule } from 'primeng/accordion';
import { TimelineModule } from 'primeng/timeline';
import { SliderModule } from 'primeng/slider';
import { PanelItem } from '../items-panel/panel-item';
import { CertificationItem } from '../items-panel/certification-item';
import { RoutingResourceEnum } from '../../../core/enums/routing-resource-enum';
import { DialogModule } from 'primeng/dialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FloatLabelModule } from 'primeng/floatlabel';
import {
  TraceSearchCustomComponent,
} from '../../../shared/components/trace-search-custom/trace-search-custom.component';
import { LocaleSwitcherService } from '../../../core/services/locale-switcher.service';

@Component({
  selector: 'app-trace-batch',
  standalone: true,
  imports: [
    PanelModule,
    LabelDescriptionCustomComponent,
    NgIf,
    TranslateModule,
    ReactiveFormsModule,
    NgForOf,
    ButtonDirective,
    AccordionModule,
    NgClass,
    TimelineModule,
    SliderModule,
    FormsModule,
    Button,
    DialogModule,
    UpperCasePipe,
    AutoCompleteModule,
    FloatLabelModule,
    TraceSearchCustomComponent,
    DatePipe,
  ],
  templateUrl: './trace-batch.component.html',
  styleUrl: './trace-batch.component.scss',
})
export class TraceBatchComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private batchService: BatchService,
    private localeService: LocaleSwitcherService,
    public translate: TranslateService,
  ) {
    this.route.paramMap.subscribe((params) => {
      this.batchId = params.get('id') ?? '';
    });
  }

  batchId!: string;
  batch!: ResponseTraceBatchDto;
  batches!: ResponseBatchDto[];
  seedingObject!: PanelItem[];
  certificationObject!: CertificationItem[];
  distributionObjects: PanelItem[][] = [];
  certificationsNumber!: number;
  valueSlider!: number;
  dialogGoBackVisible: boolean = false;
  dialogVisible: boolean = false;
  inputInvalid: boolean = false;
  selectedBatch!: ResponseBatchDto;

  ngOnInit(): void {
    this.getTraceBatch();
  }

  changeSliderValue(min: number, max: number) {
    this.valueSlider = (max + min) / 2;
  }

  getTraceBatch(): void {
    if (this.batchId) {
      this.batchService.getTraceBatchById(this.batchId).subscribe({
        next: (response: ResponseTraceBatchDto) => {
          this.batch = response;
          this.seedingObject = [
            {
              label: 'features.trace-batch-page.producer',
              value: response.producer.name,
            },
            {
              label: 'features.trace-batch-page.date-seeding',
              value: new Date(response.seedingDate).toLocaleDateString(),
            },
            {
              label: 'features.trace-batch-page.type-seeding',
              value: response.seedingType,
            },
            {
              label: 'features.trace-batch-page.type-growing',
              value: response.growingProcessType,
            },
            {
              label: 'features.trace-batch-page.species',
              value:
                response.product.species.englishName +
                ' - ' +
                response.product.species.scientificName,
            },
            {
              label: 'features.trace-batch-page.batch-number',
              value: response.code,
            },
          ];
          this.certificationsNumber = this.countCertifications(response);
          this.certificationObject = this.buildCertificationObject(response);
          let shipments = response.shipments;
          this.distributionObjects = shipments.map((shipment) => [
            {
              label: 'features.trace-batch-page.shipment-date',
              value: shipment.shipmentDate ? formatDate(shipment.shipmentDate.toString(), 'dd-MM-yyyy HH:mm:ss', this.localeService.getLocale()): "",
            },
            {
              label: 'features.trace-batch-page.business-name',
              value: shipment.customer.businessName,
            },
            {
              label: 'features.trace-batch-page.buyer-type',
              value: shipment.customer.buyerType,
            },
            {
              label: 'features.trace-batch-page.stores',
              value: shipment.customer.numberOfStores.toString(),
            },
            {
              label: 'features.trace-batch-page.qrcodes',
              value:
                shipment.QRCodeSequenceRange.min +
                ' - ' +
                shipment.QRCodeSequenceRange.max,
            },
            {
              label: 'features.trace-batch-page.rfid',
              value: shipment.rfid ?? this.translate.instant('features.trace-batch-page.rfid-none'),
            },
            {
              label: 'features.trace-batch-page.rfid_from',
              value: shipment.rfid_from ? formatDate(shipment.rfid_from.toString(), 'dd-MM-yyyy HH:mm:ss', this.localeService.getLocale()): "",
            },
            {
              label: 'features.trace-batch-page.rfid_to',
              value: shipment.rfid_to ? formatDate(shipment.rfid_to.toString(), 'dd-MM-yyyy HH:mm:ss', this.localeService.getLocale()) : "",
            }

          ]);
          if (shipments.length > 0) {
            this.changeSliderValue(100, 100);
          } else {
            if (this.certificationsNumber > 0) {
              this.changeSliderValue(33.33, 66.66);
            } else {
              this.changeSliderValue(0, 33.33);
            }
          }
        },
        error: (err) => {
          console.error('Error fetching batch data:', err);
        },
      });
    }
  }

  private buildCertificationObject(
    response: ResponseTraceBatchDto,
  ): CertificationItem[] {
    return [
      {
        label: 'features.trace-batch-page.OMG',
        value: response.OMGFreeCertAttachments,
      },
      {
        label: 'features.trace-batch-page.antibiotic',
        value: response.antibioticFreeCertAttachments,
      },
      {
        label: 'features.trace-batch-page.nutrients',
        value: response.nutrientsFreeCertAttachments,
      },
    ].filter((item) => item.value && item.value.length > 0);
  }

  private countCertifications(response: ResponseTraceBatchDto): number {
    let certificationCount = 0;
    if (response.OMGFreeCertAttachments.length > 0) {
      certificationCount++;
    }
    if (response.antibioticFreeCertAttachments.length > 0) {
      certificationCount++;
    }
    if (response.nutrientsFreeCertAttachments.length > 0) {
      certificationCount++;
    }
    return certificationCount;
  }

  openPreview(attachment: ResponseAttachmentDto) {
    console.log(attachment);
    this.batchService
      .getAttachment(this.batch._id, attachment.fileKey)
      .subscribe({
        next: (url: string) => {
          this.downloadFile(url, attachment.originalName);
        },
        error: (err) => {
          console.error(err);
        },
      });
  }

  private downloadFile(url: string, fileName: string | undefined) {
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName || 'downloaded-file';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  closeDialogBack(): void {
    this.dialogGoBackVisible = false;
  }

  goBack(): void {
    void this.router.navigate([RoutingResourceEnum.HOME]);
  }

  openDialogTraceBatch() {
    this.dialogVisible = true;
    this.getBatches();
  }

  getBatches() {
    this.batchService.index().subscribe({
      next: (batches: ResponseBatchDto[]) => {
        this.batches = batches;
      },
      error: (err) => {
      },
    });
  }

  generatePDF() {
    this.batchService.exportPDF(this.batch._id).subscribe({
      next: (response: Blob) => {
        const fileURL = URL.createObjectURL(response);
        window.open(fileURL, '_blank');
      },
      error: (err) => {
      },
    });
  }
}
